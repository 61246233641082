#login-page {
  display: flex;
  flex-direction: column;

  &::after {
    content: "";
    background-position: center;
    background-size: cover;
    position: absolute;
    inset: 0px;
    z-index: -1;
  }

  main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    .card {
      width: 100%;
      margin-bottom: 8%;

      display: flex;
      flex-direction: column;
      gap: 32px;

      h3 {
        font-size: 24px;
        margin: 0px;
      }

      h3 + p {
        margin-top: 12px;
        margin-bottom: 0px;
      }

      .form {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .login-action {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding-top: 8px;

          .ant-btn {
            background-color: #009ee2;
          }

          .login-disclaimer {
            font-size: 11px;
            color: var(--color-gray-base);

            a {
              color: #0076a8;
            }
          }
        }

        .login-sub-action {
          display: flex;
          justify-content: center;
          margin-top: 17px;
        }
      }
    }
  }
}
